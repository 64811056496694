import { Button, Space, Typography } from 'antd';
import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import styles from './Step.module.less';

const cx = classNames.bind(styles);

const { Title, Paragraph } = Typography;

function ThirdStep({ handleMoveStep }) {
  return (
    <div className={cx({ wrapper: true })}>
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Title>“신청이 완료되었습니다!”​</Title>
        <Paragraph style={{ textAlign: 'center' }}>
          <p>빠른 시간 내에 신청내용을 확인하겠습니다.​</p>
          <p>관리자 승인 후 이용 할 수 있습니다. ​</p>
        </Paragraph>
        <Link to="/">
          <Button className={cx({ defaultBtn: true })}>로그인 화면가기</Button>
        </Link>
      </Space>
    </div>
  );
}

export default ThirdStep;
