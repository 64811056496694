import React from 'react';
import useQuery from '#hooks/useQuery';

const useIndex = () => {
  const { query } = useQuery();

  const limit = React.useMemo(() => {
    if (!query?.limit) {
      return 10;
    }
    return query.limit;
  }, [query]);

  const page = React.useMemo(() => {
    if (!query?.page) {
      return 0;
    }
    return query.page;
  }, [query]);

  const getIndex = React.useCallback(
    (index) => {
      return index + 1 + page * limit;
    },
    [page, limit],
  );

  return {
    getIndex,
    limit,
    page,
  };
};

export default useIndex;
