import handleError from '#utils/handleError';
import { MinusCircleOutlined, MinusCircleTwoTone, PlusCircleOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import React from 'react';
import { Button, Modal, Form, Input, Select, Space, Typography, Statistic, Upload } from 'antd';
import classNames from 'classnames/bind';
import produce from 'immer';
import styles from './Step.module.less';
import FileUpload from '../../datalist/FileUpload';
import AddressModal from '../../AddressModal';
import RegexObj from '#utils/regex';
import { useReduxStore } from '#hooks/useReduxStore';
import { restApiNotUsingToken } from '#apis';
import { kakaoLocalApi } from '../../../apis';
import useSms from '../../../hooks/useSms';

const { Countdown } = Statistic;
const cx = classNames.bind(styles);

const NEEDED_KEYS = {
  NAME: { key: 'userName', required: true, error: '이름을 입력해주세요.' },
  USER_ID: {
    key: 'userId',
    required: true,
    error: '아이디를 입력해주세요.',
    validate: RegexObj.id,
    validateError: '아이디는 숫자, 대소문자로 이루어진 6~20 사이여야 합니다.',
    label: '아이디',
    url: 'user-id',
  },
  PASSWORD: {
    key: 'password',
    required: true,
    error: '비밀번호를 입력해주세요.',
    validate: RegexObj.password,
    validateError: '비밀번호는 영문대소문자와 !@#$%^&*()의 특수문자로 이루어진 6~16 사이여야 합니다.',
    label: '비밀번호',
  },
  EMAIL: {
    key: 'email',
    required: true,
    error: '이메일을 입력해주세요',
    validate: RegexObj.email,
    validateError: '이메일 형식에 맞춰 입력해주세요.',
    label: '이메일',
    url: 'email',
  },
  PHONE: {
    key: 'phone',
    required: true,
    error: '핸드폰 번호를 입력해주세요.',
    validate: RegexObj.phone,
    validateError: '유효한 핸드폰 번호를 입력해주세요.',
    label: '핸드폰',
  },
  ID_CARD_IMG: {
    key: 'identificationFile',
    required: true,
    error: '신분증을 첨부해주세요.',
    label: '신분증',
  },
  BANK_NAME: {
    key: 'bankCode',
    required: true,
    error: '은행명을 선택해주세요',
    label: '은행명',
  },
  ACCOUNT_HOLDER: {
    key: 'accountHolder',
    required: true,
    error: '예금주를 입력해주세요',
    label: '예금주',
  },
  ACCOUNT_NUMBER: {
    key: 'accountNumber',
    required: true,
    error: '계좌번호를 입력해주세요',
    label: '계좌번호',
  },
  UID: { key: 'uuid', required: true, label: '인증번호', error: '핸드폰 번호 인증을 진행하세요.' },
  PARTNERS: {
    key: 'storeIdList',
    required: true,
    label: '파트너사 설정',
    error: '파트너사를 입력해주세요',
  },
};

function SecondStep({ handleMoveStep }) {
  const { tree } = useReduxStore('codes');
  const { verify, check } = useSms();

  const [duplicateCheck, setDuplicateCheck] = React.useState({
    USER_ID: null,
    EMAIL: null,
    // BUSINESS_NUMBER: null,
  });

  // 핸드폰 인증번호에 필요한 state
  const [timer, setTimer] = React.useState(null);
  const [showAuthNumberInput, setShowAuthNumberInput] = React.useState(true);
  const [authNum, setAuthNum] = React.useState('');
  const [isFinishPhoneAuth, setIsFinishPhoneAuth] = React.useState(false);

  const [showPostCode, setShowPostCode] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [partnerResult, setPartnerResult] = React.useState([]);
  const [passwordConfirm, setPasswordConfirm] = React.useState('');
  const [requestBody, setRequestBody] = React.useState({
    [NEEDED_KEYS.NAME.key]: null,
    [NEEDED_KEYS.USER_ID.key]: null,
    [NEEDED_KEYS.PASSWORD.key]: null,
    [NEEDED_KEYS.EMAIL.key]: null,
    [NEEDED_KEYS.PHONE.key]: null,
    [NEEDED_KEYS.BANK_NAME.key]: null,
    [NEEDED_KEYS.ID_CARD_IMG.key]: null,
    [NEEDED_KEYS.ACCOUNT_HOLDER.key]: null,
    [NEEDED_KEYS.ACCOUNT_NUMBER.key]: null,
    [NEEDED_KEYS.UID.key]: null,
    [NEEDED_KEYS.PARTNERS.key]: [''],
  });

  // 인증 번호 전송을 눌렀을 때
  const askAuthNumber = () => {
    if (!RegexObj.phone.test(requestBody[NEEDED_KEYS.PHONE.key])) {
      alert('알맞은 핸드폰 번호를 입력해주세요');
      return;
    }

    const newPhoneNumber = requestBody[NEEDED_KEYS.PHONE.key];

    restApiNotUsingToken
      .get('/md/accounts/check/phone', {
        params: {
          phone: newPhoneNumber,
        },
      })
      .then(async () => {
        await verify(requestBody[NEEDED_KEYS.PHONE.key]);
        setAuthNum('');
        setTimer(Date.now() + 180 * 1000);
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          Modal.warning({ content: '이미 가입된 아이디가 존재합니다.', centered: true, okText: '확인' });
          return;
        }

        if (e?.response?.status === 422) {
          Modal.warning({
            content: '알맞은 핸드폰 번호를 입력해주세요.',
            centered: true,
            okText: '확인',
          });
          return;
        }

        Modal.warning({
          content: '인증번호 전송에 실패했습니다. 잠시후 시도해주세요.',
          centered: true,
          okText: '확인',
        });
      });
  };

  // 인증 번호 확인 할때
  const handleAuth = () => {
    check({ code: authNum })
      .then((uuid) => {
        setRequestBody(
          produce((draft) => {
            draft[NEEDED_KEYS.UID.key] = uuid;
          }),
        );
        setShowAuthNumberInput(false);
        setIsFinishPhoneAuth(true);
      })
      .catch((error) => {
        Modal.error({
          title: '인증 실패',
          content: error?.response?.data?.message || '인증에 실패했습니다.',
          centered: true,
          okText: '확인',
        });
      });
  };

  const checkValidation = () => {
    const checkList = Object.keys(NEEDED_KEYS);

    for (const key of checkList) {
      // console.log('NEEDED_KEYS[key].required ::: ', NEEDED_KEYS[key].required);
      if (NEEDED_KEYS[key].required && !requestBody[NEEDED_KEYS[key].key]) {
        Modal.warning({
          content: `${NEEDED_KEYS[key].error}`,
          centered: true,
        });
        return;
      }

      if (NEEDED_KEYS[key].validate) {
        if (!NEEDED_KEYS[key].validate.test(requestBody[NEEDED_KEYS[key].key])) {
          Modal.warning({
            content: `${NEEDED_KEYS[key].validateError}`,
            centered: true,
          });
          return;
        }
      }
    }

    for (const key of Object.keys(duplicateCheck)) {
      if (!duplicateCheck[key]) {
        Modal.warning({
          content: `${NEEDED_KEYS[key].label} 중복 확인을 완료해주세요`,
          centered: true,
        });
        return;
      }
    }

    if (passwordConfirm !== requestBody[NEEDED_KEYS.PASSWORD.key]) {
      Modal.warning({
        content: '비밀번호를 확인해주세요.',
        centered: true,
      });
      return;
    }

    return true;
  };

  // NEEDED_KEYS의 키를 첫번째 매개변수로 넘깁니다.
  const handleCheckAleadyExist = async (keyToCheck) => {
    try {
      // 중복 체크하는 벨류의 값이 없다면 confirm
      if (!requestBody[NEEDED_KEYS[keyToCheck].key]) {
        Modal.warning({
          content: NEEDED_KEYS[keyToCheck].error,
          centered: true,
        });
        return;
      }

      await restApiNotUsingToken.get(`/md/accounts/check/${NEEDED_KEYS[keyToCheck].url}`, {
        params: {
          [NEEDED_KEYS[keyToCheck].key]: requestBody[[NEEDED_KEYS[keyToCheck].key]],
        },
      });

      setDuplicateCheck(
        produce((draft) => {
          draft[keyToCheck] = true;
        }),
      );

      Modal.success({
        content: `사용할 수 있는 ${NEEDED_KEYS[keyToCheck].label} 입니다.`,
        centered: true,
      });

      // console.log('response :::: ', response);
      // console.log('넘어 왔다!!!!');
    } catch (e) {
      // console.log('e :::: ', e.response);
      if (e?.response?.status === 400) {
        Modal.warning({
          content: `이미 존재하는 ${NEEDED_KEYS[keyToCheck].label} 입니다.`,
          centered: true,
        });
      } else if (e?.response?.status === 422) {
        Modal.warning({
          content: `알맞은 ${NEEDED_KEYS[keyToCheck].label} 을/를 입력하세요.`,
          centered: true,
        });
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (!checkValidation()) return;
      setIsSubmitting(true);
      const newRequestBody = { ...requestBody };
      newRequestBody.phone = `${newRequestBody.phone}`;
      await restApiNotUsingToken.post('/md/accounts', newRequestBody);
      setIsSubmitting(false);
      handleMoveStep(2);
    } catch (e) {
      Modal.error({
        content: 'md 등록에 실패 했습니다.',
      });
      console.warn(`error in post partner : `, e);
      setIsSubmitting(false);
    }
  };

  const handleSearch = async (v) => {
    if (!v) {
      setPartnerResult([]);
      return;
    }
    try {
      const { data } = await restApiNotUsingToken.get(`/md/accounts/find/userAndStore`, {
        params: { keyword: v },
      });
      setPartnerResult(
        data
          .filter((v) => !!v.userId && !!v.storeName)
          .map((v) => ({
            value: v.id,
            label: `${v.userId}(${v.storeName})`,
            disabled: requestBody[NEEDED_KEYS.PARTNERS.key]?.includes(v.id),
          })),
      );
    } catch (e) {
      handleError(e);
    }
  };

  return (
    <div className={cx({ wrapper: true })}>
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <Form
          name="basic"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Space size="middle" direction="vertical" style={{ width: '100%' }}>
            <Form.Item label="이름" name="userName" required>
              <Input
                value={requestBody[NEEDED_KEYS.NAME.key]}
                placeholder="이름을 입력하세요"
                maxLength={20}
                disabled={isSubmitting}
                onChange={(e) => {
                  setRequestBody(
                    produce((draft) => {
                      draft[NEEDED_KEYS.NAME.key] = e.target.value;
                    }),
                  );
                }}
                style={{ width: '100%', marginRight: 10 }}
              />
            </Form.Item>
            <Form.Item label="아이디" name="userId" required>
              <div style={{ display: 'flex' }}>
                <Input
                  maxLength={20}
                  minLength={6}
                  disabled={isSubmitting}
                  placeholder="6자리 이상, 20자리 이하, 영문 대소문자, 숫자 조합"
                  onChange={(e) => {
                    if (duplicateCheck.USER_ID) {
                      setDuplicateCheck(
                        produce((draft) => {
                          draft.USER_ID = null;
                        }),
                      );
                    }
                    setRequestBody(
                      produce((draft) => {
                        draft[NEEDED_KEYS.USER_ID.key] = e.target.value;
                      }),
                    );
                  }}
                  value={requestBody[NEEDED_KEYS.USER_ID.key]}
                  style={{ width: 320, marginRight: 10 }}
                />
                <Button
                  disabled={duplicateCheck.USER_ID}
                  onClick={() => {
                    handleCheckAleadyExist('USER_ID');
                  }}
                >
                  중복체크
                </Button>
              </div>
            </Form.Item>
            <Form.Item label="비밀번호" name="password" required>
              <Input.Password
                disabled={isSubmitting}
                placeholder="영문대소문자와 !@#$%^&*()의 특수문자로 이루어진 6~16 사이"
                onChange={(e) => {
                  setRequestBody(
                    produce((draft) => {
                      draft[NEEDED_KEYS.PASSWORD.key] = e.target.value;
                    }),
                  );
                }}
                value={requestBody[NEEDED_KEYS.PASSWORD.key]}
                style={{ width: '100%', marginRight: 10 }}
              />
            </Form.Item>
            <Form.Item
              label="비밀번호 확인"
              name="passwordConfirm"
              rules={[
                { required: true, message: '비밀번호를 확인 해주세요.' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('비밀번호가 일치하지 않습니다.'));
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="비밀번호를 확인해주세요"
                disabled={isSubmitting}
                onChange={(e) => {
                  setPasswordConfirm(e.target.value);
                }}
                style={{ width: '100%', marginRight: 10 }}
              />
            </Form.Item>
            <Form.Item label="이메일" name="email" required>
              <div style={{ display: 'flex' }}>
                <Input
                  placeholder="알맞은 이메일을 입력해주세요"
                  disabled={isSubmitting}
                  onChange={(e) => {
                    if (duplicateCheck.EMAIL) {
                      setDuplicateCheck(
                        produce((draft) => {
                          draft.EMAIL = null;
                        }),
                      );
                    }
                    setRequestBody(
                      produce((draft) => {
                        draft[NEEDED_KEYS.EMAIL.key] = e.target.value;
                      }),
                    );
                  }}
                  value={requestBody[NEEDED_KEYS.EMAIL.key]}
                  style={{ width: 355, marginRight: 10 }}
                />
                <Button
                  disabled={duplicateCheck.EMAIL}
                  onClick={() => {
                    handleCheckAleadyExist('EMAIL');
                  }}
                >
                  중복체크
                </Button>
              </div>
            </Form.Item>
            <Form.Item label="휴대전화" name="phone" required>
              <div>
                <div style={{ display: 'flex' }}>
                  <Input
                    disabled={isFinishPhoneAuth || isSubmitting}
                    placeholder="'-'를 제외한 핸드폰 번호를 입력하세요."
                    onChange={(e) => {
                      setRequestBody(
                        produce((draft) => {
                          draft[NEEDED_KEYS.PHONE.key] = e.target.value;
                        }),
                      );
                    }}
                    value={requestBody[NEEDED_KEYS.PHONE.key]}
                    style={{ width: '100%', marginRight: 10 }}
                  />
                  <Button disabled={isFinishPhoneAuth || !requestBody[NEEDED_KEYS.PHONE.key]} onClick={askAuthNumber}>
                    {isFinishPhoneAuth ? '휴대폰 인증 완료' : '인증번호 발송'}
                  </Button>
                </div>
                {showAuthNumberInput && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 10,
                    }}
                  >
                    <Input
                      id="sign-in-button"
                      disabled={!timer || isFinishPhoneAuth || isSubmitting}
                      onChange={(e) => {
                        setAuthNum(e.target.value);
                      }}
                      maxLength={15}
                      value={authNum}
                      style={{ width: 200, marginRight: 10 }}
                    />
                    <Button disabled={isFinishPhoneAuth || !authNum} onClick={handleAuth}>
                      {isFinishPhoneAuth ? '휴대폰 인증 완료' : '확인'}
                    </Button>

                    {timer && (
                      <>
                        <div style={{ width: 10 }} />
                        <Countdown
                          valueStyle={{ fontSize: 10 }}
                          value={timer}
                          onFinish={() => {
                            setShowAuthNumberInput(false);
                          }}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </Form.Item>
            <Form.Item label="신분증" name="identificationFile" required>
              <Space direction="vertical">
                <FileUpload
                  accept={'image/*'}
                  disabled={isSubmitting}
                  items={[]}
                  onDoneChange={(fileList) => {
                    setRequestBody(
                      produce((draft) => {
                        draft[NEEDED_KEYS.ID_CARD_IMG.key] =
                          fileList.map(({ response }) => response[0].id)?.[0] || null;
                      }),
                    );
                  }}
                  beforeUpload={(file) => {
                    const isAvailable = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'].includes(file.type);

                    if (!isAvailable) {
                      Modal.warning({
                        content: `jpg, jpeg, png, pdf 확장자만 가능합니다.`,
                        centered: true,
                        okText: '확인',
                      });
                      return Upload.LIST_IGNORE;
                    }

                    if (file.size > 8e6) {
                      Modal.warning({
                        content: '10MB 이상의 파일은 불가능합니다.',
                        centered: true,
                        okText: '확인',
                      });
                      return Upload.LIST_IGNORE;
                    }

                    return isAvailable || Upload.LIST_IGNORE;
                  }}
                  maxCount={1}
                />
                <Typography.Paragraph>
                  <ul>
                    <li style={{ marginBlock: 10 }}>첨부파일은 JPG, JPEG, PNG, PDF파일만 첨부할 수 있습니다.</li>
                    <li>(최대용량 10MB)</li>
                  </ul>
                </Typography.Paragraph>
              </Space>
            </Form.Item>
            <Form.Item label="은행명" name="bankCode" required>
              <Select
                placeholder="은행 선택"
                disabled={isSubmitting}
                onChange={(e) => {
                  setRequestBody(
                    produce((draft) => {
                      draft[NEEDED_KEYS.BANK_NAME.key] = e;
                    }),
                  );
                }}
                style={{ width: 300 }}
              >
                {tree?.PAAB?.items.map((value) => (
                  <Select.Option key={value.code} value={value.code}>
                    {value.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="예금주" name="acccountHolder" required>
              <Input
                placeholder="예금주명을 입력해주세요"
                disabled={isSubmitting}
                onChange={(e) =>
                  setRequestBody(
                    produce((draft) => {
                      draft[NEEDED_KEYS.ACCOUNT_HOLDER.key] = e.target.value;
                    }),
                  )
                }
                value={requestBody[NEEDED_KEYS.ACCOUNT_HOLDER.key]}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item label="계좌번호" name="accountNumber" required>
              <Input
                disabled={isSubmitting}
                placeholder="정확한 계좌번호를 입력해주세요"
                onChange={(e) =>
                  setRequestBody(
                    produce((draft) => {
                      draft[NEEDED_KEYS.ACCOUNT_NUMBER.key] = e.target.value;
                    }),
                  )
                }
                value={requestBody[NEEDED_KEYS.ACCOUNT_NUMBER.key]}
                style={{ width: '100%' }}
              />
            </Form.Item>
            <Form.Item style={{ flexDirection: 'column' }} label={'파트너사설정'} name="storeIdList" required>
              {requestBody[NEEDED_KEYS.PARTNERS.key].map((v, i) => (
                <Form.Item
                  style={{ marginLeft: 20, marginBlock: 15, minWidth: 450 }}
                  label={`파트너${i + 1}`}
                  name="storeIdList"
                >
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Select
                      suffixIcon={null}
                      showSearch
                      disabled={isSubmitting}
                      optionFilterProp={'label'}
                      placeholder="파트너 아이디를 입력 해주세요"
                      onBlur={() => setPartnerResult([])}
                      onSearch={handleSearch}
                      onChange={(value) =>
                        setRequestBody(
                          produce((draft) => {
                            draft[NEEDED_KEYS.PARTNERS.key][i] = value;
                          }),
                        )
                      }
                      options={partnerResult}
                      value={requestBody[NEEDED_KEYS.PARTNERS.key][i]}
                      style={{ width: 200, marginLeft: -20 }}
                    />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {requestBody[NEEDED_KEYS.PARTNERS.key]?.length === i + 1 && (
                        <PlusCircleTwoTone
                          size={32}
                          onClick={() => {
                            setRequestBody(
                              produce((draft) => {
                                draft[NEEDED_KEYS.PARTNERS.key] = [...requestBody[NEEDED_KEYS.PARTNERS.key], ''];
                              }),
                            );
                          }}
                          style={{
                            marginLeft: 10,
                            fontSize: 20,
                            cursor: 'pointer',
                          }}
                          twoToneColor={'#4c72f1'}
                        />
                      )}
                      {requestBody[NEEDED_KEYS.PARTNERS.key]?.length > 1 && (
                        <MinusCircleTwoTone
                          onClick={() => {
                            setRequestBody(
                              produce((draft) => {
                                draft[NEEDED_KEYS.PARTNERS.key].splice(i, 1);
                              }),
                            );
                          }}
                          style={{ marginInline: 10, fontSize: 20, cursor: 'pointer' }}
                          twoToneColor={'#4c72f1'}
                        />
                      )}
                    </div>
                  </div>
                </Form.Item>
              ))}
            </Form.Item>

            <div className={cx({ bottomBtnWrapper: true })} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                disabled={isSubmitting}
                onClick={() => {
                  handleMoveStep(0);
                }}
                className={cx({ defaultBtn: true })}
              >
                이전단계
              </Button>
              <Button
                disabled={isSubmitting}
                className={cx({ defaultBtn: true })}
                type="primary"
                htmlType="submit"
                onClick={handleSubmit}
              >
                가입신청
              </Button>
            </div>
          </Space>
        </Form>
      </Space>
    </div>
  );
}

export default SecondStep;
