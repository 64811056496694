import React from 'react';
import FetchElement from '#components/FetchElement';
import { restApi } from '#apis/index';
import styles from './ProductDetails.module.less';
import formatComma from '#utils/formatComma';
import classNames from '#utils/classNames';
import useFetch from '#hooks/useFetch';

const MemoizedProductDetails = React.memo(({ productId, delivery, product }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const productTags = useFetch([]);

  React.useEffect(() => {
    productTags.fetch(restApi.get(`/products/${productId}/tags`));
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        {product?.images?.length > 0 && (
          <div className={styles.thumbnailContainer}>
            <img className={styles.thumbnail} src={product?.images[0]} alt="썸네일" />
          </div>
        )}
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>
            {product?.productName} {product?.isBest && <b className={styles.best}>BEST</b>}
          </h3>
          <div className={styles.ratingContainer}>
            <div className={styles.ratings}>
              {[...Array(5)].map((_, index) => {
                return (
                  <img
                    className={styles.rating}
                    src={product?.score >= index + 1 ? '/assets/icon_star_blue.png' : '/assets/icon_star_gray.png'}
                    alt="star"
                  />
                );
              })}
            </div>
            <span className={styles.totalRating}>{product?.score?.toFixed(1)}</span>
            <span className={styles.reviewCount}>리뷰 {formatComma(product?.reviewCount)}</span>
          </div>
          <div className={styles.priceContainer}>
            {product?.discountRate > 0 && <span className={styles.discountRate}>{product?.discountRate}%</span>}
            <span className={styles.price}>
              <b>{formatComma(product?.price)}</b>원
            </span>
          </div>
          <div className={styles.metaContainer}>
            <span className={styles.storeName}>{product?.storeName}</span>
          </div>
        </div>
      </div>
      <div className={styles.deliveryInfoContainer}>
        <h4 className={styles.sectionTitle}>배송정보</h4>
        <dl className={styles.deliveryInfo}>
          <dt className={styles.deliveryInfoTitle}>배송비</dt>
          <dd className={styles.deliveryInfoDescription}>
            {delivery?.amount > 0 ? `${formatComma(delivery?.amount)}원` : '무료'}
            {delivery?.extraAmount > 0 && (
              <>
                <br />
                제주도 도서산간지역 ${formatComma(delivery?.extraAmount)}원
              </>
            )}
          </dd>
        </dl>
        <dl className={styles.deliveryInfo}>
          <dt className={styles.deliveryInfoTitle}>배송기간</dt>
          <dd className={styles.deliveryInfoDescription}>{delivery?.period}일 이내 도착 예상</dd>
        </dl>
        <dl className={styles.deliveryInfo}>
          <dt className={styles.deliveryInfoTitle}>제조사</dt>
          <dd className={styles.deliveryInfoDescription}>{delivery?.manufacturer}</dd>
        </dl>
      </div>
      <div className={styles.navigationContainer}>
        <a className={`${styles.navigation} ${styles.active}`}>상품정보</a>
        <a className={styles.navigation}>
          리뷰 <b className={styles.count}>{product?.reviewCount}</b>
        </a>
        <a className={styles.navigation}>
          문의 <b className={styles.count}>{product?.questionCount}</b>
        </a>
      </div>
      <div className={classNames(styles.contentsContainer, isOpen && styles.active)}>
        <div className={styles.contents} dangerouslySetInnerHTML={{ __html: product?.introduction }} />
        {!isOpen && (
          <button
            className={styles.moreButton}
            type="button"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            상세정보 보기
          </button>
        )}
      </div>
      {productTags.data?.length > 0 && (
        <div className={styles.tagContainer}>
          <h4 className={styles.sectionTitle}>관련태그</h4>
          <div className={styles.tags}>
            {productTags.data?.map(({ id, tagName }) => {
              return (
                <span key={id} className={styles.tag}>
                  # {tagName}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
});

function ProductDetails({ productId }) {
  return (
    <FetchElement
      fetch={() => restApi.get(`/products/${productId}`)}
      Component={(data) => <MemoizedProductDetails productId={productId} {...data} />}
    />
  );
}

export default ProductDetails;
