import { Button, DatePicker, Select } from 'antd';
import React from 'react';
import useFetch from '#hooks/useFetch';
import { restApi } from '../../apis';
import moment from 'moment';
import dayjs from 'dayjs';

const AddAdvertisementItem = ({
  onChange,
  firstArea,
  extra1,
  extra2,
  period,
  endAt,
  startAt,
  onRemove,
  onCheck,
  isChecked,
}) => {
  const areaCodes2 = useFetch();

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  const optionPeriods = React.useMemo(
    () => [
      { value: 10, label: '10일' },
      { value: 20, label: '20일' },
      { value: 30, label: '30일' },
    ],
    [],
  );

  return (
    <>
      <div
        style={{
          backgroundColor: 'white',
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Select
          style={{ width: 180 }}
          value={extra1}
          options={firstArea?.map((area) => ({ label: area, value: area })) ?? []}
          placeholder="시/도"
          onChange={async (value) => {
            await areaCodes2.fetch(restApi.get(`/area-codes/filter/${value}`));
            onChange({ extra1: value, extra2: null });
          }}
          allowClear
          disabled={!(firstArea?.length > 0)}
        />
      </div>
      <div
        style={{
          backgroundColor: 'white',
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Select
          value={extra2}
          loading={areaCodes2.loading}
          onChange={async (value) => {
            onChange({ extra2: value });
          }}
          options={areaCodes2.data?.map((area) => ({ label: area, value: area })) ?? []}
          style={{ width: 180 }}
          disabled={!(areaCodes2.data?.length > 0)}
          placeholder="시/군/구"
          allowClear
        />
      </div>
      <div
        style={{
          backgroundColor: 'white',
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <DatePicker
          disabledDate={disabledDate}
          value={startAt ? moment(startAt) : null}
          onChange={(value) => {
            if (!value) {
              onChange({ startAt: null, endAt: null });
              return;
            }

            onChange({
              startAt: value.format('YYYY-MM-DD'),
              endAt: dayjs(value.format('YYYY-MM-DD'))
                .add(period - 1, 'day')
                .format('YYYY-MM-DD'),
            });
          }}
        />
      </div>
      <div
        style={{
          backgroundColor: 'white',
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Select
          value={period}
          disabled={!startAt}
          style={{ width: 180 }}
          options={optionPeriods}
          onChange={(value) => {
            const endAt = dayjs(startAt)
              .add(value - 1, 'day')
              .format('YYYY-MM-DD');
            onChange({ period: value, endAt });
          }}
        />
      </div>
      <div
        style={{
          backgroundColor: 'white',
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {endAt}
      </div>
      <div
        style={{
          backgroundColor: 'white',
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          disabled={!extra1 || !extra2 || !period || !endAt || !startAt || isChecked}
          onClick={onCheck}
          style={{ width: 150 }}
          type="default"
        >
          {isChecked ? '확인 완료' : '광고 가능 여부'}
        </Button>
      </div>
      <div
        style={{
          backgroundColor: 'white',
          height: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button onClick={onRemove} style={{ width: 100 }} type="default">
          삭제
        </Button>
      </div>
    </>
  );
};

export default AddAdvertisementItem;
