import { restApiNotUsingToken } from '#apis';
import React from 'react';

export default function useSms() {
  const [uuid, setUuid] = React.useState(null);
  const [phone, setPhone] = React.useState(null);

  async function check({ code }) {
    await restApiNotUsingToken.put('/common/sms/auth-sms/register/check', {
      uuid,
      code,
      phoneNumber: phone,
    });

    return uuid;
  }

  async function verify(phone, target, extra) {
    let url = '/common/sms/auth-sms/register';
    const body = {
      phoneNumber: phone,
      phone,
    };
    if (target === 'password') {
      url = '/common/sms/md/auth-sms/find/password';
      body.userId = extra;
    } else if (target === 'id') {
      url = '/common/sms/md/auth-sms/find/userId';
      body.userName = extra;
    }
    const { data: uuid } = await restApiNotUsingToken.post(url, body);
    setUuid(uuid);
    setPhone(phone);
    return {
      uuid,
    };
  }

  return {
    data: {
      uuid,
      phone,
    },
    verify,
    check,
  };
}
