import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authReducer';
import codesReducer from './codesReducer';
import categoriesReducer from './categoriesReducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  codes: codesReducer,
  categories: categoriesReducer,
});
