import { createSlice } from '@reduxjs/toolkit';
import { restApi } from '../../apis';

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    store: {
      tree: {},
      map: {},
    },
    product: {
      tree: {},
      map: {},
    },
  },
  reducers: {
    setCategories: (state, action) => {
      state.store = action.payload.store;
      state.product = action.payload.product;
    },
  },
});

export const { setCategories } = categoriesSlice.actions;

export const categories = (config) => {
  return async (dispatch) => {
    const [{ data: storeData }, { data: productData }] = await Promise.all([
      restApi.get('/categories/store'),
      restApi.get('/categories/product/v2'),
    ]);
    const payload = { store: storeData, product: productData };
    dispatch(setCategories(payload));
    return payload;
  };
};

const categoriesReducer = categoriesSlice.reducer;

export default categoriesReducer;
