import React from 'react';
import { Modal } from 'antd';
import { Postcode } from '@actbase/react-daum-postcode';

function AddressModal({ showPostCode = false, onCancel, onSelected }) {
  return (
    <Modal
      visible={showPostCode}
      title="주소찾기"
      bodyStyle={{ padding: 0, margin: 0 }}
      width={500}
      centered
      footer={false}
      onCancel={onCancel}
    >
      <Postcode style={{ width: '100%' }} onSelected={onSelected} />
    </Modal>
  );
}

export default AddressModal;
