function removeDot(str) {
  return str.replace(/(^0)(?=[^.])/, '');
}

function replComma(str) {
  return removeDot(str.replace(/(^[\d,]+\.(?:\d*[^0])?)(0*)$/, '$1').replace(/\.$/, ''));
}

function addComma(str) {
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const formatComma = (number, input) => {
  if (!number) {
    return '0';
  }
  const numbers = number.toString().split('.');
  numbers[0] = addComma(numbers[0]);

  if (input) {
    if (typeof numbers[1] === 'string') return removeDot([numbers[0], numbers[1]].join('.'));
    return removeDot(numbers[0]);
  }
  if (typeof number === 'number') {
    return number.toLocaleString();
  }
  if (typeof numbers[1] === 'string') return replComma([numbers[0], numbers[1]].join('.'));
  return replComma(numbers[0]);
};

export default formatComma;
