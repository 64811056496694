import React from 'react';
import { Spin } from 'antd';
import useFetch from '../hooks/useFetch';

const FetchElement = React.forwardRef(({ fetch = () => {}, Component }, ref) => {
  const { data, setData, fetch: fetchHookHandler, loading } = useFetch({});

  const refresh = () => {
    fetchHookHandler(fetch());
  };

  React.useEffect(() => {
    fetchHookHandler(fetch());
  }, []);

  React.useImperativeHandle(ref, () => ({
    data,
    setData,
    refresh,
  }));

  if (loading) {
    return <Spin />;
  }

  return <Component {...data} />;
});

export default FetchElement;
