import React from 'react';
import { Form, Spin } from 'antd';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'query-string';

function SearchForm({ children, params, onInit = () => {}, onReset = () => {}, ...props }) {
  const [mount, setMount] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  const handleSubmit = React.useCallback(
    (params) => {
      navigate(
        `?${qs.stringify(
          Object.entries(params).reduce(
            (p, [key, value]) => (value === undefined || value === null || value === '' ? p : { ...p, [key]: value }),
            {},
          ),
          { arrayFormat: 'repeat' },
        )}`,
        { replace: true },
      );
    },
    [navigate],
  );

  const handleFinish = React.useCallback(() => {
    const paramsEntries = [];
    for (const entry of searchParams.entries()) {
      paramsEntries.push(entry);
    }
    const _p = paramsEntries.reduce((p, [k, v]) => {
      if (p[k]) {
        if (Array.isArray(p[k])) {
          p[k].push(v);
        } else {
          p[k] = [p[k], v];
        }
        return p;
      }
      return { ...p, [k]: v };
    }, {});
    const _params = {
      ...params,
      limit: _p.limit,
      page: '0',
    };
    handleSubmit(_params);
  }, [handleSubmit, params, searchParams]);

  const init = React.useCallback(() => {
    setMount(false);
    const paramsEntries = [];
    for (const entry of searchParams.entries()) {
      paramsEntries.push(entry);
    }
    const _params = {
      ...params,
      ...paramsEntries.reduce((p, [k, v]) => {
        if (p[k]) {
          if (Array.isArray(p[k])) {
            p[k].push(v);
          } else {
            p[k] = [p[k], v];
          }
          return p;
        }
        return { ...p, [k]: v };
      }, {}),
    };
    onInit(_params);
    handleSubmit(_params);
    setMount(true);
  }, [handleSubmit, onInit, params, searchParams]);

  const handleReset = React.useCallback(() => {
    if (typeof onReset === 'function') {
      handleSubmit(onReset() ?? params);
    }
  }, [handleSubmit, onReset, params]);

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    const paramsEntries = [];
    for (const entry of searchParams.entries()) {
      paramsEntries.push(entry);
    }
    const _params = {
      ...params,
      ...paramsEntries.reduce((p, [k, v]) => {
        if (p[k]) {
          if (Array.isArray(p[k])) {
            p[k].push(v);
          } else {
            p[k] = [p[k], v];
          }
          return p;
        }
        return { ...p, [k]: v };
      }, {}),
    };
    onInit(_params);
  }, [location]);

  if (!mount) {
    return <Spin />;
  }

  return (
    <Form onFinish={handleFinish} onReset={handleReset} {...props}>
      {children}
    </Form>
  );
}

export default React.memo(SearchForm);
