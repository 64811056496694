import React from 'react';
import classNames from 'classnames/bind';
import styles from '../User.module.less';
import { Button, Form, Input, Modal, Space, Statistic } from 'antd';
import produce from 'immer';
import { useNavigate } from 'react-router-dom';
import RegexObj from '#utils/regex';
import handleError from '#utils/handleError';
import { restApi } from '#apis';
import { restApiNotUsingToken } from '#apis';
import useSms from '#hooks/useSms';

const { Countdown } = Statistic;
const cx = classNames.bind(styles);

function PartnerFindId() {
  const navigate = useNavigate();
  const { verify, check } = useSms();

  const [firstTakeAuth, setfirstTakeAuth] = React.useState(true);
  const [timer, setTimer] = React.useState(null);
  const [timerFinish, setTimerFinish] = React.useState(false);
  const [canAuth, setCanAuth] = React.useState(true);

  const REQUEST_KEYS = React.useMemo(
    () => ({
      BUSINESS_NAME: 'userName',
      PHONE: 'phone',
    }),
    [],
  );

  const [authNumber, setAuthNumber] = React.useState(null);
  const [requestBody, setRequestBody] = React.useState({
    [REQUEST_KEYS.BUSINESS_NAME]: null,
    [REQUEST_KEYS.PHONE]: null,
  });

  const handleFindId = () => {
    const params = { ...requestBody, code: authNumber };

    restApiNotUsingToken
      .get('/md/accounts/find/userId', { params })
      .then(({ data }) => {
        Modal.success({
          content: (
            <div>
              <p>회원님의 아이디는 {data}입니다.</p>
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: 40, marginBottom: -25 }}>
                <Button
                  style={{ marginRight: 10 }}
                  type="primary"
                  onClick={() => {
                    Modal.destroyAll();
                    navigate('/login', { replace: true });
                  }}
                >
                  로그인
                </Button>
                <Button
                  onClick={() => {
                    Modal.destroyAll();
                    navigate(`/find_pw?name=${data}`);
                  }}
                >
                  비밀번호 찾기
                </Button>
              </div>
            </div>
          ),
          centered: true,
          cancelButtonProps: { style: { display: 'block' } },
          okButtonProps: { style: { display: 'none' } },
        });
      })
      .catch((e) => {
        console.warn(e.response);
        if (e?.response?.status === 404) {
          alert('가입자 정보를 찾을 수 없습니다. \n고객센터에 문의해주세요. \n\n고객센터: 1644-1154');
          // navigate('/login', { replace: true });
          return;
        }
        alert(
          e?.response?.data?.message || '아이디 찾기에 실패했습니다.\n고객센터에 문의해주세요. \n\n고객센터: 1644-1154',
        );
        // navigate('/login', { replace: true });
        handleError(e);
      });
  };

  //   .confirm(requestBody[REQUEST_KEYS.AUTH_NUM])
  //   .then((result) => {
  //     const { authNumber, ...rest } = requestBody;
  //     const newRequestBody = rest;
  //     newRequestBody.uid = result.user.uid;
  //     newRequestBody[REQUEST_KEYS.PHONE] = '+82' + rest[REQUEST_KEYS.PHONE].slice(1);

  //     restApi
  //       .post('/partners/accounts/find/userId', newRequestBody)
  //       .then(({ data }) => {
  //         Modal.success({
  //           content: (
  //             <div>
  //               <p>회원님의 아이디는 {data}입니다.</p>
  //               <div style={{ display: 'flex', justifyContent: 'center', marginTop: 40, marginBottom: -25 }}>
  //                 <Button
  //                   style={{ marginRight: 10 }}
  //                   type="primary"
  //                   onClick={() => {
  //                     Modal.destroyAll();
  //                     navigate('/login', { replace: true });
  //                   }}
  //                 >
  //                   로그인
  //                 </Button>
  //                 <Button
  //                   onClick={() => {
  //                     Modal.destroyAll();
  //                     navigate(`/find_pw?name=${data}`);
  //                   }}
  //                 >
  //                   비밀번호 찾기
  //                 </Button>
  //               </div>
  //             </div>
  //           ),
  //           centered: true,
  //           cancelButtonProps: { style: { display: 'block' } },
  //           okButtonProps: { style: { display: 'none' } },
  //         });
  //       })
  //       .catch((e) => {
  //         console.warn(e.response);
  //         if (e?.response?.status === 404) {
  //           alert('가입자 정보를 찾을 수 없습니다. \n고객센터에 문의해주세요. \n\n고객센터: 1644-1154');
  //           navigate('/login', { replace: true });
  //         }
  //         handleError(e);
  //       });
  //   })
  //   .catch((e) => {
  //     console.warn(e);
  //     alert('인증코드가 일치하지 않습니다. 다시 시도해주세요.');
  //   });

  const askAuthNumber = () => {
    if (!RegexObj.phone.test(requestBody[REQUEST_KEYS.PHONE])) {
      alert('알맞은 핸드폰 번호를 입력해주세요');
      return;
    }

    if (firstTakeAuth) {
      setfirstTakeAuth(false);
    }

    verify(requestBody[REQUEST_KEYS.PHONE], 'id', requestBody[REQUEST_KEYS.BUSINESS_NAME])
      .then(() => {
        const now = Date.now();
        setAuthNumber(null);
        setTimer(now + 180 * 1000);
        setTimerFinish(false);
      })
      .catch((error) => {
        console.warn(error);
        Modal.error({
          content: '인증번호 전송에 실패 했습니다. 관리자에 문의해주세요.',
        });
      });
  };

  return (
    <div className={cx({ wrapper: true, finder: true })}>
      <h4>아이디 찾기</h4>
      <Space direction="vertical" align="center" style={{ width: '100%', marginTop: 30 }}>
        <Form
          name="basic"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Space size="middle" direction="vertical" style={{ width: '100%' }}>
            <Form.Item label="MD이름" required>
              <Input
                value={requestBody[REQUEST_KEYS.BUSINESS_NAME]}
                placeholder="MD 이름"
                onChange={(e) => {
                  setRequestBody(
                    produce((draft) => {
                      draft[REQUEST_KEYS.BUSINESS_NAME] = e.target.value;
                    }),
                  );
                }}
                style={{ width: 335, marginRight: 10 }}
              />
            </Form.Item>
            <Form.Item
              label={
                <>
                  <p style={{ marginRight: 10 }}>휴대전화</p>
                  {typeof timer === 'number' && (
                    <Countdown
                      valueStyle={{ fontSize: 10 }}
                      value={timer}
                      onFinish={() => {
                        setCanAuth(false);
                        setTimerFinish(true);
                      }}
                    />
                  )}
                </>
              }
              required
            >
              <div style={{ display: 'flex' }}>
                <Input
                  placeholder="'-' 제외 번호만 입력"
                  disabled={!requestBody[REQUEST_KEYS.BUSINESS_NAME]}
                  onChange={(e) => {
                    setRequestBody(
                      produce((draft) => {
                        draft[REQUEST_KEYS.PHONE] = e.target.value;
                      }),
                    );
                  }}
                  value={requestBody[REQUEST_KEYS.PHONE]}
                  style={{ width: 355, marginRight: 10 }}
                />
                <Button
                  id="sign-in-button"
                  disabled={!requestBody[REQUEST_KEYS.BUSINESS_NAME] || !requestBody[REQUEST_KEYS.PHONE]}
                  onClick={askAuthNumber}
                >
                  <p>{firstTakeAuth ? '인증번호 전송' : '재전송'}</p>
                </Button>
              </div>
            </Form.Item>
            <Form.Item label="인증번호" required>
              <Input
                id="sign-in-button"
                value={authNumber}
                placeholder="인증번호를 입력하세요"
                disabled={!requestBody[REQUEST_KEYS.PHONE] || firstTakeAuth || timerFinish}
                onChange={(e) => {
                  setCanAuth(true);
                  setAuthNumber(e.target.value);
                }}
                maxLength={10}
                style={{ width: 335, marginRight: 10 }}
              />
            </Form.Item>
          </Space>
        </Form>
      </Space>
      <div style={{ display: 'flex', marginTop: 50, alignItems: 'center' }}>
        <Button
          onClick={() => {
            navigate('/login');
          }}
          type="ghost"
          style={{ flex: 1 }}
        >
          로그인 화면
        </Button>
        <div style={{ width: 20 }} />
        <Button disabled={!canAuth || !authNumber} onClick={handleFindId} type="primary" style={{ flex: 1 }}>
          아이디 찾기
        </Button>
      </div>
    </div>
  );
}

export default PartnerFindId;
