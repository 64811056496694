import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyA9s7LZvNltZqes05I7h6jp28aYUfyd78k',
  authDomain: 'homegrit-53fc3.firebaseapp.com',
  projectId: 'homegrit-53fc3',
  storageBucket: 'homegrit-53fc3.appspot.com',
  messagingSenderId: '1075013851506',
  appId: '1:1075013851506:web:cd619d40b68155914fe5eb',
  measurementId: 'G-KMT39D3MGV',
};

const firebase = initializeApp(firebaseConfig);

export const auth = getAuth();

export default firebase;
