import React from 'react';
import { Button, Form, Input, Modal, Space, Statistic } from 'antd';
import produce from 'immer';
import qs from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import RegexObj from '#utils/regex';
import handleError from '#utils/handleError';
import { restApiNotUsingToken } from '#apis';
import useSms from '#hooks/useSms';

const { Countdown } = Statistic;

function FirstStep({ onFinishFindPw }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { verify, check } = useSms();

  const [firstTakeAuth, setfirstTakeAuth] = React.useState(true);
  const [timer, setTimer] = React.useState(null);
  const [timerFinish, setTimerFinish] = React.useState(false);
  const [canAuth, setCanAuth] = React.useState(true);

  const REQUEST_KEYS = React.useMemo(
    () => ({
      USERID: 'userId',
      PHONE: 'phone',
      UUID: 'uuid',
    }),
    [],
  );

  const [authNumber, setAuthNumber] = React.useState(null);

  const [requestBody, setRequestBody] = React.useState({
    [REQUEST_KEYS.USERID]: null,
    [REQUEST_KEYS.PHONE]: null,
  });

  const askAuthNumber = () => {
    if (!RegexObj.phone.test(requestBody[REQUEST_KEYS.PHONE])) {
      alert('알맞은 핸드폰 번호를 입력해주세요');
      return;
    }

    if (firstTakeAuth) {
      setfirstTakeAuth(false);
    }

    verify(requestBody[REQUEST_KEYS.PHONE], 'password', requestBody[REQUEST_KEYS.USERID])
      .then(() => {
        const now = Date.now();
        setAuthNumber(null);
        setTimer(now + 180 * 1000);
        setTimerFinish(false);
      })
      .catch((error) => {
        console.warn(error);
        Modal.error({
          content: '인증번호 전송에 실패 했습니다. 관리자에 문의해주세요.',
        });
      });
  };

  const handleFindPw = () => {
    const params = { ...requestBody, code: authNumber };
    restApiNotUsingToken
      .get('/md/accounts/find/pwd/check/validate', { params })
      .then(({ data }) => {
        onFinishFindPw(params);
      })
      .catch((e) => {
        Modal.warning({
          title: '비밀번호 찾기',
          content: e?.response?.data?.message || '비밀번호 찾기에 실패 했습니다. 잠시후 시도 해주세요.',
          onOk: () => {
            navigate('/login', { replace: true });
          },
        });
        handleError(e);
      });
  };

  React.useEffect(() => {
    if (!location.search) return;

    setRequestBody((prev) => ({ ...prev, [REQUEST_KEYS.USERID]: qs.parse(location.search).name }));
  }, [location?.search, REQUEST_KEYS.USERID]);

  return (
    <>
      <h4>비밀번호 찾기</h4>
      <Space direction="vertical" align="center" style={{ width: '100%', marginTop: 30 }}>
        <Form
          name="basic"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          autoComplete="off"
        >
          <Space size="middle" direction="vertical" style={{ width: '100%' }}>
            <Form.Item label="아이디" required>
              <Input
                value={requestBody[REQUEST_KEYS.USERID]}
                placeholder="아이디"
                onChange={(e) => {
                  setRequestBody(
                    produce((draft) => {
                      draft[REQUEST_KEYS.USERID] = e.target.value;
                    }),
                  );
                }}
                style={{ width: 335, marginRight: 10 }}
              />
            </Form.Item>
            <Form.Item
              label={
                <>
                  <p style={{ marginRight: 10 }}>휴대전화</p>
                  {typeof timer === 'number' && (
                    <Countdown
                      valueStyle={{ fontSize: 10 }}
                      value={timer}
                      onFinish={() => {
                        setCanAuth(false);
                        setTimerFinish(true);
                      }}
                    />
                  )}
                </>
              }
              required
            >
              <div style={{ display: 'flex' }}>
                <Input
                  placeholder="'-' 제외 번호만 입력"
                  disabled={!requestBody[REQUEST_KEYS.USERID]}
                  onChange={(e) => {
                    setRequestBody(
                      produce((draft) => {
                        draft[REQUEST_KEYS.PHONE] = e.target.value;
                      }),
                    );
                  }}
                  value={requestBody[REQUEST_KEYS.PHONE]}
                  style={{ width: 355, marginRight: 10 }}
                />
                <Button
                  id="sign-in-button"
                  disabled={!requestBody[REQUEST_KEYS.USERID] || !requestBody[REQUEST_KEYS.PHONE]}
                  onClick={askAuthNumber}
                >
                  <p>{firstTakeAuth ? '인증번호 전송' : '재전송'}</p>
                </Button>
              </div>
            </Form.Item>
            <Form.Item label="인증번호" required>
              <Input
                id="sign-in-button"
                value={authNumber}
                placeholder="인증번호를 입력하세요"
                disabled={!requestBody[REQUEST_KEYS.PHONE] || firstTakeAuth || timerFinish}
                onChange={(e) => {
                  setCanAuth(true);
                  setAuthNumber(e.target.value);
                }}
                style={{ width: 335, marginRight: 10 }}
              />
            </Form.Item>
          </Space>
        </Form>
      </Space>
      <div style={{ display: 'flex', marginTop: 50, alignItems: 'center' }}>
        <Button
          onClick={() => {
            navigate('/login');
          }}
          type="ghost"
          style={{ flex: 1 }}
        >
          로그인 화면
        </Button>
        <div style={{ width: 20 }} />
        <Button disabled={!canAuth || !authNumber} onClick={handleFindPw} type="primary" style={{ flex: 1 }}>
          비밀번호 찾기
        </Button>
      </div>
    </>
  );
}

export default FirstStep;
