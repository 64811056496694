import React, { useState } from 'react';
import { Button, Checkbox, Form, Input, Modal, Space } from 'antd';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { setToken } from '../../apis';
import { me } from '../../data/reducers/authReducer';
import { postOauthToken } from '../../apis/auth';
import { useNavigate } from 'react-router-dom';
import styles from './User.module.less';
import classNames from 'classnames/bind';
import Text from 'antd/lib/typography/Text';

const cx = classNames.bind(styles);

function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies(['ID', 'SAVEID']);
  const dispatch = useDispatch();
  const [username, setUsername] = React.useState(null);
  const [password, setPassword] = React.useState(null);

  const handleCheckAutoSave = (event) => {
    if (cookies.SAVEID) {
      removeCookies('SAVEID');
      removeCookies('ID');
    } else {
      setCookies('SAVEID', event.target.checked);
    }
  };

  const handleSubmit = async () => {
    if (loading) return;
    if (cookies.SAVEID) setCookies('ID', username);
    try {
      setLoading(true);
      const requestBody = {
        username,
        password,
        grant_type: 'password',
      };

      const { data: responseData } = await postOauthToken(requestBody);
      setLoading(false);
      setToken(responseData);

      await dispatch(me());
      navigate('/');
    } catch (e) {
      console.warn('error in login ::: ', e.response);
      const description = e.response.data.error_description;
      let msg = '로그인 정보가 일치하지 않습니다.';
      if (!!description && description === 'confirm') {
        msg = (
          <Space direction="vertical">
            <Text>MD 가입이 승인되지 않았습니다. </Text>
            <Text>※ 고객센터 전화번호 1644-1154</Text>
          </Space>
        );
      }
      Modal.warning({ okText: '확인', centered: true, content: msg });
    }
    setLoading(false);
  };

  const handleJoin = React.useCallback(() => {
    navigate('/join');
  }, [navigate]);

  const handleFindId = React.useCallback(() => {
    navigate('/find_id');
  }, [navigate]);

  const handleFindPw = React.useCallback(() => {
    navigate('/find_pw');
  }, [navigate]);

  React.useEffect(() => {
    if (!cookies) return;
    if (!username) {
      setUsername(cookies.ID);
    }
  }, [cookies, username]);

  return (
    <div className={cx({ wrapper: true })}>
      <Form onFinish={handleSubmit}>
        <h2>홈그릿 서비스</h2>
        <h4>MD</h4>
        <Form.Item noStyle rules={[{ required: true, message: '아이디를 입력해주세요.' }]}>
          <Input
            prefix="아이디"
            className={styles.input}
            value={username}
            onChange={(event) => {
              setUsername(event.target.value);
            }}
            allowClear
          />
        </Form.Item>
        <Form.Item noStyle rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}>
          <Input.Password
            prefix="비밀번호"
            className={styles.input}
            value={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            allowClear
          />
        </Form.Item>
        <Checkbox style={{ marginTop: 10 }} checked={cookies.SAVEID || false} onChange={handleCheckAutoSave}>
          아이디 저장하기
        </Checkbox>
        <div style={{ display: 'flex', marginTop: 10, alignItems: 'center' }}>
          <Button onClick={handleJoin} type="text" style={{ flex: 1 }}>
            회원가입
          </Button>
          <div style={{ width: 1, height: 15, backgroundColor: 'gray' }} />
          <Button onClick={handleFindId} type="text" style={{ flex: 1 }}>
            아이디 찾기
          </Button>
          <div style={{ width: 1, height: 15, backgroundColor: 'gray' }} />
          <Button onClick={handleFindPw} type="text" style={{ flex: 1 }}>
            비밀번호 재설정
          </Button>
        </div>
        <Button
          onClick={handleSubmit}
          block
          className={cx({ submit: true })}
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          로그인
        </Button>
      </Form>
      <br />
      <p>※ 아이디/비밀번호 분실 시 관리자 및 담당 영업사업에게 문의하세요.</p>
    </div>
  );
}

export default Login;
