import styles from '#components/common/CardWrappers.module.less';
import React, {useEffect, useLayoutEffect} from 'react';

const CardWrapperMultiDimensional = ({data, onClick = () => {}, ...args}) => {
  const [maxWidth,setMaxWidth] = React.useState(window.innerWidth-(window.innerWidth>1023?450:210));
  useLayoutEffect(()=>{

    const updateWindowDimensions = () => {
      setMaxWidth(window.innerWidth- (window.innerWidth>1023?450:210))
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions)
  },[])
  return (
      <div  className={styles.container} style={{ maxWidth,
        overflowX: 'scroll',flex:'none'}}
            {...args} >
        {data.map(rowArr =>
            <div className={styles.wrapper}>
              {rowArr.map(row=>(
                  <div className={styles.box}>
                    <label>{row.title || '\u00A0'}</label>
                    <p>{row.value}</p>
                    <div style={{marginTop: 10}}>{row.content}</div>
                  </div>
              ))}
            </div>,
        )}
      </div>

  );
}
export default CardWrapperMultiDimensional
