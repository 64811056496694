import React from 'react';

function RequiredOrNotLabel({ label = '라벨을 입력하세요', required, ...args }) {
  return (
    <div {...args}>
      <span>{label}</span>
      {required && <span style={{ color: 'red' }}> *</span>}
    </div>
  );
}

export default React.memo(RequiredOrNotLabel);
