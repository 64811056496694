import { createSlice } from '@reduxjs/toolkit';
import { restApi } from '#apis/index';

export const codesSlice = createSlice({
  name: 'codes',
  initialState: {
    tree: {},
    map: {},
  },
  reducers: {
    setCodes: (state, action) => {
      state.tree = action.payload.tree;
      state.map = action.payload.map;
    },
  },
});

export const { setCodes } = codesSlice.actions;

export const codes = (config) => {
  return async (dispatch) => {
    const { data: responseData } = await restApi.get('/codes');
    dispatch(setCodes(responseData));
    return responseData;
  };
};

const codesReducer = codesSlice.reducer;

export default codesReducer;
