import { Select } from 'antd';
import React from 'react';
import AddAdvertisementItem from './AddAdvertisementItem';

const AddAdvertisementList = ({ list, firstArea, onChangeList, onRemove, onCheck }) => {
  return (
    <div
      style={{
        marginBlock: '50px',
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 200px)',
        gap: 1,
        width: 'fit-content',
        backgroundColor: '#f0f0f0',
        padding: '1px',
      }}
    >
      <div
        style={{
          backgroundColor: '#eae8e8',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 40,
        }}
      >
        시/도
      </div>
      <div
        style={{
          backgroundColor: '#eae8e8',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: 40,
        }}
      >
        시/군/구
      </div>
      <div style={{ backgroundColor: '#eae8e8', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        시작일
      </div>
      <div style={{ backgroundColor: '#eae8e8', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        기간
      </div>
      <div style={{ backgroundColor: '#eae8e8', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        종료일
      </div>
      <div style={{ backgroundColor: '#eae8e8', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        광고 가능 여부
      </div>
      <div style={{ backgroundColor: '#eae8e8', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        삭제
      </div>

      {list?.map((item) => (
        <AddAdvertisementItem
          key={item.id}
          onRemove={onRemove(item.id)}
          firstArea={firstArea}
          onChange={onChangeList(item.id)}
          onCheck={onCheck(item.id)}
          {...item}
        />
      ))}
    </div>
  );
};

export default AddAdvertisementList;
