import React from 'react';

const useFetch = (initialData = null) => {
  const [data, setData] = React.useState(initialData);
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const fetch = async (FetchInstance) => {
    setLoading(true);
    try {
      const responseData = await FetchInstance;
      setData(responseData.data);
      return responseData;
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const clear = () => {
    setData(initialData);
    setError(null);
    setLoading(false);
  };

  return { data, setData, error, loading, fetch, clear };
};

export default useFetch;
