import { Modal } from 'antd';
import React from 'react';

const useCheckSearchTypeAndKeyword = (searchType, keyword) => {
  const onClickSubmit = (e) => {
    if (!searchType && keyword) {
      e.preventDefault();
      Modal.warning({
        content: '검색 옵션을 선택해 주세요.',
      });
    } else if (searchType && !keyword) {
      e.preventDefault();
      Modal.warning({
        content: '키워드를 입력해 주세요.',
      });
    }
  };
  return [onClickSubmit];
};

export default useCheckSearchTypeAndKeyword;
