export const LIMIT = 10; // default pagination content LIMIT         //페이지네이션 페이지당 콘텐츠 갯수 기본값
export const RANGE = 10; // default pagination content RANGE         //페이지네이션 페이징 네비게이션 범위 기본값
export const PAGE = 0; // default pagination content START PAGE    //페이지네이션 페이지 시작 기본

// default pagination params data
export const PAGING_PARAMS = { keywords: [], sorts: [], limit: LIMIT, page: PAGE, range: RANGE };

export const BANNER_PLACE_CODE = {
  main: 'BP001',
  shopping: 'BP002',
  store: 'BP003',
  community: 'BP004',
};

export const BANNER_PLACE_NAME = {
  BP001: '메인화면 배너',
  BP002: '서브메인(쇼핑) 배너',
  BP003: '서브메인(상점) 배너',
  BP004: '서브메인(커뮤니티) 배너',
};

export const DAY = {
  MONDAY: '월',
  TUESDAY: '화',
  WEDNESDAY: '수',
  THURSDAY: '목',
  FRIDAY: '금',
  SATURDAY: '토',
  SUNDAY: '일',
};
